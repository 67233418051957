@mixin sliderMask ($widthMap, $align: false) {
	@each $bp, $sliderWidth in $widthMap {
		$containerWidth: map-get($container-max-widths, $bp);

		$p1: calc((100% - #{$containerWidth}) / 2);
		$p2: null;
		$p3: null;
		$p4: calc(100% - #{$p1});

		@if $align {
			$p2: $p1;
			$p3: calc(#{$p1} + #{$sliderWidth});
		} @else {
			$p2: calc((100% - #{$sliderWidth}) / 2);
			$p3: calc(100% - #{$p2});
		}

		@include media-breakpoint-up($bp) {
			.tiny-slider { max-width: #{$sliderWidth}; }

			&:before {
				[dir="ltr"] & {
					-webkit-mask-image: linear-gradient(to right, black 0%, black $p1, transparent $p2, transparent $p3, black $p4, black 100%);
					mask-image: linear-gradient(to right, black 0%, black $p1, transparent $p2, transparent $p3, black $p4, black 100%);
				}

				[dir="rtl"] & {
					-webkit-mask-image: linear-gradient(to left, black 0%, black $p1, transparent $p2, transparent $p3, black $p4, black 100%);
					mask-image: linear-gradient(to left, black 0%, black $p1, transparent $p2, transparent $p3, black $p4, black 100%);
				}
			}

			@if $align {
				$addedWidth: calc(100% + (#{$containerWidth} - #{$sliderWidth}));
				$addedWidthCounter: calc(100% + (#{$containerWidth} - #{$sliderWidth} - 32px));

				&.joint.articles .tns-outer {
					.tns-nav {
						width: $addedWidth;
					}

					.tns-liveregion {
						width: $addedWidthCounter;
					}
				}
			}
		}
	}
}
