@mixin btn-theme($theme_name, $btn-themes: $btn-themes) {
	$theme-vals: map-get($btn-themes, $theme_name);

	.btn.theme-#{$theme_name},
	.button-circle.theme-#{$theme_name} {
		// FULL COLORS
		&.full {
			color: map-get($theme-vals, "font");
			.icon { filter: map-get($theme-vals, "filter"); }
			&:before {
				background: map-get($theme-vals, "bg");
				border: none;
			}

			// Disabled comes first so active can properly restyle
			&.disabled.alt-opacity,
			&:disabled.alt-opacity {

				// On inverse only alt opacity is different.
				.inverse & {
					color: map-get($theme-vals, "font-disabled-inverse");
					.icon { filter: map-get($theme-vals, "filter-disabled-inverse"); }
					&:before {
						background: map-get($theme-vals, "bg-disabled-inverse");
					}
				}

				&,
				.light & {
					color: map-get($theme-vals, "font-disabled");
					.icon { filter: map-get($theme-vals, "filter-disabled"); }
					&:before {
						background: map-get($theme-vals, "bg-disabled");
					}
				}
			}
		}


		// OUTLINE COLORS
		&.outline {
			// on inverse, outline has same colors for any theme color. Inverse must come before light!
			.inverse & {
				color: $white;
				.icon { filter: $filter-white; }
				&:before {
					background: transparent;
					border-color: $white;
				}

				// Disabled comes first so active can properly restyle
				&.disabled.alt-opacity,
				&:disabled.alt-opacity {
					color: map-get($theme-vals, "font-outline-disabled-inverse");
					.icon { filter: map-get($theme-vals, "filter-outline-disabled-inverse"); }
					&:before {
						background: map-get($theme-vals, "bg-outline-disabled-inverse");
						border-color: map-get($theme-vals, "border-outline-disabled-inverse");
					}
				}
			}

			&,
			.light & {
				color: map-get($theme-vals, "font-outline");
				.icon { filter: map-get($theme-vals, "filter-outline"); }
				&:before {
					background:  map-get($theme-vals, "bg-outline");
					border-color: map-get($theme-vals, "border-outline");
				}

				&.disabled.alt-opacity,
				&:disabled.alt-opacity {
					color: map-get($theme-vals, "font-outline-disabled");
					.icon { filter: map-get($theme-vals, "filter-outline-disabled"); }
					&:before {
						background: map-get($theme-vals, "bg-outline-disabled");
						border-color: map-get($theme-vals, "border-outline-disabled");
					}
				}
			}
		}

		&.disabled,
		&:disabled {
			opacity: $btn-disabled-opacity;
			box-shadow: none;

			&.alt-opacity {
				opacity: 1;
			}
		}

		&:hover {
			@include btn-hover($theme_name);
		}
	}

	
	// SHADOWS
	// sizes are in variables
	$shadow-color1: map-get($theme-vals, "shadow1");
	$shadow-color2: map-get($theme-vals, "shadow2");

	$shadow-opacity: map-get($theme-vals, "shadow-opacity");

	$circle-shadow-opacity1: map-get($theme-vals, "circle-shadow-opacity1");
	$circle-shadow-opacity2: map-get($theme-vals, "circle-shadow-opacity2");

	// BUTTONS
	.btn.theme-#{$theme_name}:not(.disabled) {// FULL COLORS
		&.full {
			box-shadow:
				$btn-shadow1 rgba($shadow-color1, $shadow-opacity),
				$btn-shadow2 rgba($shadow-color2, $shadow-opacity);
		}

		&:hover {
			@include btn-hover-shadow($theme_name);
		}
	}

	// CIRCLE BUTTONS
	.button-circle.theme-#{$theme_name}:not(:disabled) {
		box-shadow:
			$btn-circle-shadow rgba($shadow-color1, $circle-shadow-opacity1),
			$btn-circle-shadow rgba($shadow-color2, $circle-shadow-opacity2);

		&:hover {
			@include button-circle-hover-shadow($theme_name);
		}
	}
}
