@mixin btn-hover($theme_name, $btn-themes: $btn-themes) {
	$theme-vals: map-get($btn-themes, $theme_name);

	// OUTLINE COLORS
	&.outline:not(:disabled):not(.disabled) {
		// on inverse, outline has same colors for any theme color. Inverse must come before light!
		.inverse & {
			color: $blue-dark;
			.icon { filter: $filter-blue-dark; }
			&:before {
				background: $white;
			}
		}

		&,
		.light & {
			color: map-get($theme-vals, "font-outline-hover");
			.icon { filter: map-get($theme-vals, "filter-outline-hover"); }
			&:before {
				background: map-get($theme-vals, "bg-outline-hover");
			}
		}
	}
}


@mixin btn-hover-shadow($theme_name, $btn-themes: $btn-themes) {
	$theme-vals: map-get($btn-themes, $theme_name);

	// SHADOWS
	// sizes are in variables
	$shadow-color1: map-get($theme-vals, "shadow1");
	$shadow-color2: map-get($theme-vals, "shadow2");
	$shadow-opacity-hover: map-get($theme-vals, "shadow-hover-opacity");

	// BUTTONS
	&.size-sm {
		box-shadow:
			$btn-shad-hover-sm1 rgba($shadow-color1, $shadow-opacity-hover),
			$btn-shad-hover-sm2 rgba($shadow-color2, $shadow-opacity-hover);
	}
	&.size-md {
		box-shadow:
			$btn-shad-hover-md1 rgba($shadow-color1, $shadow-opacity-hover),
			$btn-shad-hover-md2 rgba($shadow-color2, $shadow-opacity-hover);
	}
	&.size-lg {
		box-shadow:
			$btn-shad-hover-lg1 rgba($shadow-color1, $shadow-opacity-hover),
			$btn-shad-hover-lg2 rgba($shadow-color2, $shadow-opacity-hover);
	}
}


@mixin button-circle-hover-shadow($theme_name, $btn-themes: $btn-themes) {
	$theme-vals: map-get($btn-themes, $theme_name);

	// SHADOWS
	// sizes are in variables
	$shadow-color1: map-get($theme-vals, "shadow1");
	$shadow-color2: map-get($theme-vals, "shadow2");
	$circle-shadow-opacity-hover1: map-get($theme-vals, "circle-shadow-opacity-hover1");
	$circle-shadow-opacity-hover2: map-get($theme-vals, "circle-shadow-opacity-hover2");

	// CIRCLE BUTTONS
	&.size-sm,
	&.size-md {
		box-shadow:
			$btn-circle-shadow-hover rgba($shadow-color1, $circle-shadow-opacity-hover1),
			$btn-circle-shadow-hover rgba($shadow-color2, $circle-shadow-opacity-hover2);
	}
}
