@import "../variables";
@import "../mixins";
@import "../../bootstrap/mixins";


// adjust width and position
.trustpilot-widget {
	@include media-breakpoint-up(lg) {
		width: 421px;
		transform: translateX(-18px);
	}
}
