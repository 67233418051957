/*
	Avast Mixin for image from sprite.

	$name - name of particular icon
	$size - size of the image
*/

@mixin sprite-icon($name, $size) {
	$mobileSize: if($size == 16, $size, $size - 8);
	$sprite-rows: map-get($sprite-icons, rows);

	background-image: url("https://static3.avast.com/web/i/v3/components/icons/sprites/icons-#{$mobileSize}.svg?v2");
	width: #{$mobileSize}px;
	height: #{$mobileSize}px;

	@include media-breakpoint-up(lg) {
		background-image: url("https://static3.avast.com/web/i/v3/components/icons/sprites/icons-#{$size}.svg?v2");
		width: #{$size}px;
		height: #{$size}px;
	}

	@each $row, $row-data in $sprite-rows {
		@each $this-name in $row-data {
			@if $this-name == $name {
				// Icon Position in sprite:
				$position: index($row-data, $name)-1;
				$column: $row - 1;

				//add row and col gap to all items - except first row and each fist item in row
				$gap-position: if($position == 0, 0, 16);
				$gap-row: if($column == 0, 0, 16);

				// cordinates calculation
				$ico-x: ($position * $size) + ($position * $gap-position);
				$ico-y: ($column * $size) + ($column * $gap-row);
				$ico-x-m: ($position * ($mobileSize)) + ($position * $gap-position);
				$ico-y-m: ($column * ($mobileSize)) + ($column * $gap-row);

				background-position: -#{$ico-x-m}px -#{$ico-y-m}px;

				@include media-breakpoint-up(lg) {
					background-position: -#{$ico-x}px -#{$ico-y}px;
				}
			}
		}
	}
}
