/*
* Base unit for vertical rhytm.
* To be used primary for line heights (to keep typografy aligned with vertical rhytm grid) top and bottom margins and paddings heights.
*/
$font-size-base: 1rem;
$base-unit: $font-size-base;
$bu: $font-size-base; // shorter base unit variable



// Options
//
// Quickly modify global styling by enabling or disabling optional features.
$enable-shadows: true;
$enable-validation-icons: false;


// Spacing BS override
//
// scss-docs-start spacer-variables-maps
$spacer: $bu;
$spacers: (
	0: 0,
	8: $spacer/2,    //8
	12: $spacer*.75, //12
	16: $spacer,     //16
	24: $spacer*1.5, //24
	32: $spacer*2,   //32
	40: $spacer*2.5, //40
	48: $spacer*3,   //48
	56: $spacer*3.5, //56
	64: $spacer*4, //64
);


// Grid columns
//
// Set the number of columns and specify the width of the gutters.
$grid-gutter-width: $bu*2;


/* GRID
================
*/
// Grid breakpoint values:
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1280px;
$breakpoint-xl2: 1366px;
$breakpoint-xxl: 1600px;

// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: (
xs: 0,
sm: $breakpoint-sm,
md: $breakpoint-md,
lg: $breakpoint-lg,
xl: $breakpoint-xl,
xl2: $breakpoint-xl2,
xxl: $breakpoint-xxl
);

// Grid container values:
$container-sm: 540px;
$container-md: 720px;
$container-lg: 960px;
$container-xl: 1224px;
$container-xl2: 1320px;
$container-xxl: 1392px;

// Define the maximum width of `.container` for different screen sizes.
$container-max-widths: (
sm: $container-sm,
md: $container-md,
lg: $container-lg,
xl: $container-xl,
xl2: $container-xl2,
xxl: $container-xxl
);



//
//	🗺 z-index map
//
$z-index: (
	ds-nav__toggle:      1013,	// has to be higher than <ds-nav> z-index – it fixes visual overlay bug
	ds-header:           1012,
	ds-nav:              1012,
	cmp-message-bar:     1012,	// prevents <cmp-message-bar> being overlaped by <ds-backdrop>
	cmp-region-selector: 1012,
	cmp-sticky-bar:      1011,
	cmp-popover:         1010,
	ds-backdrop:         1009
);



/*
COLORS
=================
*/

/*
* Basic color pallete as variables
*/
$blue-dark: #071d2b; // (Dark Blue) - main text color, main bg color
$white: #fff; // (White)

$orange-bright: #FF7800; // (Bright Orange)
$orange-light: #FFB370; // (Light Orange)
$orange-pale: #FFDDBF; // (Pale Orange)
$orange-faint: #FFF1E5; // (Faint Orange)
$orange-off-white: #FFF9F5; // (Off-White Orange)

$blue-bright: #0070f6; // (Bright Blue)
$blue-bright-alt: #0067ed; // (Bright Blue Alt for orange backgrounds)
$blue-light: #7BB5FB; // (Light Blue)
$blue-pale: #D4E7FF; // (Pale Blue)
$blue-faint: #F0F7FF; // (Faint Blue)

$green-bright: #9ae437; // (Bright Green)
$green-light: #B7EB6E; // (Light Green)
$green-pale: #D5F2A5; // (Pale Green)
$green-faint: #effbdf; // (Faint Green)

$red: #d93511; // (Red)
$red-inverse: #FF5833; // (Red Inverse)
$red-faint: #FFAF9D; // (Red Faint)
$yellow: #FFBF00; // (Yellow)
$yellow-inverse: #FFD900; // (Yellow Inverse)

$gray01: #0C2636; // (Gray 1)
$gray02: #143347; // (Gray 2)
$gray03: #21455C; // (Gray 3)
$gray04: #345970; // (Gray 4)
$gray05: #5C7A8C; // (Gray 5)
$gray06: #88A2B2; // (Gray 6)
$gray07: #C5D6E0; // (Gray 7)
$gray08: #E1EAF0; // (Gray 8)
$gray09: #EBF1F5; // (Gray 9)
$gray10: #F7FAFC; // (Gray 10)

$gold: linear-gradient(to bottom right, $yellow 0%, $yellow 50%, $orange-bright 100%);

// transparent color
$transparent: transparent;

// reset of bootstrap default colors maps
$grays: ();
$colors: ();
// reset of bootstrap default colors maps

// theme-colors-map (used for utility mixin -> BG, Borders and other colors)
$theme-colors: (
  "blue-dark": $blue-dark,
	"blue-bright": $blue-bright,
	"blue-bright-alt": $blue-bright-alt,
	"blue-light": $blue-light,
	"blue-pale": $blue-pale,
	"blue-faint": $blue-faint,
	"orange-bright": $orange-bright,
	"orange-light": $orange-light,
	"orange-pale": $orange-pale,
	"orange-faint": $orange-faint,
	"orange-off-white": $orange-off-white,
	"green-bright": $green-bright,
	"green-light": $green-light,
	"green-pale": $green-pale,
	"green-faint": $green-faint,
	"red": $red,
	"red-inverse": $red-inverse,
	"red-faint": $red-faint,
	"yellow": $yellow,
	"yellow-inverse": $yellow-inverse,
	"gray01": $gray01,
	"gray02": $gray02,
	"gray03": $gray03,
	"gray04": $gray04,
	"gray05": $gray05,
	"gray06": $gray06,
	"gray07": $gray07,
	"gray08": $gray08,
	"gray09": $gray09,
	"gray10": $gray10
);
// theme-colors-map



/* FILTERS
================
// For recoloring of SVG images (https://codepen.io/sosuke/pen/Pjoqqp)
*/
// Primary colors
$filter-black: brightness(0) saturate(1);
$filter-white: $filter-black invert(1) sepia(5%) hue-rotate(72deg) brightness(97%) contrast(110%);

$filter-blue-dark: $filter-black invert(9%) sepia(16%) saturate(3060%) hue-rotate(165deg) brightness(88%) contrast(97%);
$filter-blue-bright: $filter-black invert(32%) sepia(67%) saturate(4010%) hue-rotate(201deg) brightness(92%) contrast(107%);
$filter-blue-light: $filter-black invert(63%) sepia(21%) saturate(880%) hue-rotate(182deg) brightness(105%) contrast(97%);
$filter-blue-pale: $filter-black invert(84%) sepia(4%) saturate(2555%) hue-rotate(193deg) brightness(107%) contrast(101%);
$filter-blue-faint: $filter-black invert(1) sepia(48%) saturate(2107%) hue-rotate(178deg) brightness(104%) contrast(102%);

$filter-green-bright: $filter-black invert(75%) sepia(65%) saturate(418%) hue-rotate(34deg) brightness(102%) contrast(87%);
$filter-green-light: $filter-black invert(74%) sepia(57%) saturate(277%) hue-rotate(41deg) brightness(103%) contrast(99%);
$filter-green-pale: $filter-black invert(91%) sepia(15%) saturate(677%) hue-rotate(32deg) brightness(104%) contrast(90%);
$filter-green-faint: $filter-black invert(89%) sepia(20%) saturate(195%) hue-rotate(47deg) brightness(108%) contrast(97%);

$filter-orange-bright: $filter-black invert(44%) sepia(89%) saturate(777%) hue-rotate(360deg) brightness(103%) contrast(97%);
$filter-orange-light: $filter-black invert(76%) sepia(41%) saturate(705%) hue-rotate(322deg) brightness(105%) contrast(102%);
$filter-orange-pale: $filter-black invert(90%) sepia(28%) saturate(667%) hue-rotate(308deg) brightness(106%) contrast(101%);
$filter-orange-faint: $filter-black invert(96%) sepia(5%) saturate(2762%) hue-rotate(301deg) brightness(110%) contrast(101%);
$filter-orange-off-white: $filter-black invert(96%) sepia(1%) saturate(1925%) hue-rotate(323deg) brightness(96%) contrast(115%);

$filter-red: $filter-black invert(24%) sepia(76%) saturate(2256%) hue-rotate(360deg) brightness(98%) contrast(90%);
$filter-red-inverse: $filter-black invert(46%) sepia(66%) saturate(2601%) hue-rotate(336deg) brightness(112%) contrast(96%);
$filter-red-faint: $filter-black invert(68%) sepia(13%) saturate(1094%) hue-rotate(322deg) brightness(108%) contrast(102%);
$filter-yellow: $filter-black invert(67%) sepia(50%) saturate(812%) hue-rotate(2deg) brightness(104%) contrast(104%);
$filter-yellow-inverse: $filter-black invert(72%) sepia(84%) saturate(573%) hue-rotate(359deg) brightness(105%) contrast(105%);

// 10 shades of grey
$filter-gray01: $filter-black invert(11%) sepia(36%) saturate(1365%) hue-rotate(164deg) brightness(88%) contrast(94%);
$filter-gray02: $filter-black invert(18%) sepia(49%) saturate(475%) hue-rotate(169deg) brightness(92%) contrast(1);
$filter-gray03: $filter-black invert(22%) sepia(57%) saturate(406%) hue-rotate(167deg) brightness(96%) contrast(95%);
$filter-gray04: $filter-black invert(30%) sepia(37%) saturate(461%) hue-rotate(166deg) brightness(97%) contrast(92%);
$filter-gray05: $filter-black invert(51%) sepia(17%) saturate(572%) hue-rotate(165deg) brightness(86%) contrast(86%);
$filter-gray06: $filter-black invert(68%) sepia(21%) saturate(289%) hue-rotate(166deg) brightness(90%) contrast(86%);
$filter-gray07: $filter-black invert(93%) sepia(7%) saturate(370%) hue-rotate(164deg) brightness(92%) contrast(89%);
$filter-gray08: $filter-black invert(97%) sepia(2%) saturate(1243%) hue-rotate(180deg) brightness(92%) contrast(105%);
$filter-gray09: $filter-black invert(97%) sepia(3%) saturate(475%) hue-rotate(178deg) brightness(100%) contrast(93%);
$filter-gray10: $filter-black invert(89%) sepia(10%) saturate(57%) hue-rotate(182deg) brightness(108%) contrast(100%);


// theme-colors-map (used for utility mixin -> BG, Borders and other colors)
$filter-colors: (
  "black": $filter-black,
  "white": $filter-white,
  "blue-dark": $filter-blue-dark,
	"blue-bright": $filter-blue-bright,
	"blue-light": $filter-blue-light,
	"blue-pale": $filter-blue-pale,
	"blue-faint": $filter-blue-faint,
	"orange-bright": $filter-orange-bright,
	"orange-light": $filter-orange-light,
	"orange-pale": $filter-orange-pale,
	"orange-faint": $filter-orange-faint,
	"orange-off-white": $filter-orange-off-white,
	"green-bright": $filter-green-bright,
	"green-light": $filter-green-light,
	"green-pale": $filter-green-pale,
	"green-faint": $filter-green-faint,
	"red": $filter-red,
	"red-inverse": $filter-red-inverse,
	"red-faint": $filter-red-faint,
	"yellow": $filter-yellow,
	"yellow-inverse": $filter-yellow-inverse,
	"gray01": $filter-gray01,
	"gray02": $filter-gray02,
	"gray03": $filter-gray03,
	"gray04": $filter-gray04,
	"gray05": $filter-gray05,
	"gray06": $filter-gray06,
	"gray07": $filter-gray07,
	"gray08": $filter-gray08,
	"gray09": $filter-gray09,
	"gray10": $filter-gray10
);
// theme-colors-map


/* BODY
================
*/

// Settings for the `<body>` element.
$body-color: $blue-dark;
$body-color-light: $white;
$body-bg: $white;
$body-bg-inverse: $blue-dark;


// Basic border color
$border-color:    $gray07;

/*
TYPOGRAPHY
=================
*/
//Desktop -- TODO -> Create this as a map?
$body-1-font-size: $bu*1.375; //22px
$body-2-font-size: $bu*1.125; //18px
$body-3-font-size: $bu;       //16px
$body-4-font-size: $bu*.875;  //14px
$body-5-font-size: 13px;      //13px

$body-1-line-height: $bu*2.25; //36px
$body-2-line-height: $bu*1.875;//30px
$body-3-line-height: $bu*1.625;//26px
$body-4-line-height: $bu*1.375;//22px
$body-5-line-height: $bu*1.25; //20px

//Mobile
$body-1-font-size-m: $bu*1.125; //18px
$body-2-font-size-m: $bu*1;     //16px
$body-3-font-size-m: $bu*.875;  //14px
$body-4-font-size-m: $bu*.75;   //12px
$body-5-font-size-m: $bu*.6875; //11px

$body-1-line-height-m: $bu*1.875; //30px
$body-2-line-height-m: $bu*1.625; //26px
$body-3-line-height-m: $bu*1.375; //22px
$body-4-line-height-m: $bu*1.125; //18px
$body-5-line-height-m: $bu;       //16px

// Map od Body text attributes
$bodyFontSizes: (
	1: (
		"df":$body-1-font-size,
		"dl":$body-1-line-height,
		"mf":$body-1-font-size-m,
		"ml":$body-1-line-height-m
	),
	2: (
		"df":$body-2-font-size,
		"dl":$body-2-line-height,
		"mf":$body-2-font-size-m,
		"ml":$body-2-line-height-m
	),
	3: (
		"df":$body-3-font-size,
		"dl":$body-3-line-height,
		"mf":$body-3-font-size-m,
		"ml":$body-3-line-height-m
	),
	4: (
		"df":$body-4-font-size,
		"dl":$body-4-line-height,
		"mf":$body-4-font-size-m,
		"ml":$body-4-line-height-m
	),
	5: (
		"df":$body-5-font-size,
		"dl":$body-5-line-height,
		"mf":$body-5-font-size-m,
		"ml":$body-5-line-height-m
	)
);



/* Headings
=================
*/
//Desktop sizes
$h1-font-size: $bu*3.5;   //56px
$h2-font-size: $bu*2.875; //46px
$h3-font-size: $bu*2.125; //34px
$h4-font-size: $bu*1.75;  //28px
$h5-font-size: $bu*1.5;   //24px
$h6-font-size: $bu*1.25;  //20px
$h7-font-size: $bu*1.125; //18px

$h1-line-height: $bu*4.5;  //72px
$h2-line-height: $bu*3.75; //60px
$h3-line-height: $bu*2.75; //44px
$h4-line-height: $bu*2.25; //36px
$h5-line-height: $bu*2;    //32px
$h6-line-height: $bu*1.625;//26px
$h7-line-height: $bu*1.5;  //24px

//Mobile sizes
$h1-font-size-m: $bu*2.625;  //42px
$h2-font-size-m: $bu*2.125;  //34px
$h3-font-size-m: $bu*1.625;  //26px
$h4-font-size-m: $bu*1.3125; //21px
$h5-font-size-m: $bu*1.125;  //18px
$h6-font-size-m: $bu;        //16px
$h7-font-size-m: $bu*.875;   //14px

$h1-line-height-m: $bu*3.375; //54px
$h2-line-height-m: $bu*2.75;  //44px
$h3-line-height-m: $bu*2.125; //34px
$h4-line-height-m: $bu*1.6875;//27px
$h5-line-height-m: $bu*1.5;   //24px
$h6-line-height-m: $bu*1.25;  //20px
$h7-line-height-m: $bu*1.125; //18px

$heading-font-weight: 750;


// sprite images
$sprite-icons: (
	sizes: (16, 24, 32),
	rows: (
		1: ("arrow-right","arrow-left","arrow-up","arrow-down","download","next","back","up","down","plus","minus","cross","send"),
		2: ("refresh","external","play-oval","play","play-l","check-oval","refresh-2","next-s","back-s","up-s","down-s","last","first","bracets","sound-off","sound-on"),
		3: ("home","account","store","support","all-products","search","hardware","headset","info","hover-info","thumbs-down","thumbs-up","clipboard","article"),
		4: ("calculator","chat","filter","phone","email","warning","alarm-clock","dollar-sign","education","question","hand-right","hand-left","bullet-arrow-2","cross-oval","check-oval-color"),
		5: ("placeholder","burger","quote","money-back","pc","pin","tick","star","star-half","bullet-arrow","check-table","cross-table","time-to-read","radio-bullet","pause"),
		6: ("win","mac","android","ios","family","server","linux","multi-device","cloud"),
		7: ("facebook","instagram","linkedin","twitter","youtube","blog","share","rss")
	)
);

// breadcrumbs
$breadcrumb-item-padding-x:       $bu/2;
$breadcrumb-margin-bottom:        0;
$breadcrumb-divider-color:        $body-color;
$breadcrumb-active-color:         $body-color;
//$breadcrumb-divider:              quote("<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M6 12L9.44134 8.47043C9.74985 8.15401 9.74335 7.64737 9.42683 7.33896L6 4' stroke='#071D2B' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round'/></svg>");


/* BUTTONS
=================
*/

// Button shadow sizes
$btn-shadow1: 0px 8px 32px -16px;
$btn-shadow2: 0px 16px 32px -16px;

$btn-shad-hover-lg1: 0px 16px 40px -16px;
$btn-shad-hover-lg2: 0px 32px 40px -16px;
$btn-shad-hover-md1: 0px 12px 32px -16px;
$btn-shad-hover-md2: 0px 24px 32px -16px;
$btn-shad-hover-sm1: 0px 8px 24px -12px;
$btn-shad-hover-sm2: 0px 16px 24px -12px;

$btn-circle-shadow: 0 8px 20px -8px;
$btn-circle-shadow-hover: 0 12px 24px -8px;

// THEME MAPS
$btn-disabled-opacity: .32;
$btn-themes: (
"blue": (
	"font": $white,
	"font-outline": $blue-bright,
	"font-outline-hover": $white,
	"bg": $blue-bright,
	"bg-outline": $white,
	"bg-outline-hover": $blue-bright,
	"border-outline": $blue-bright,
	"filter": $filter-white,
	"filter-outline": $filter-blue-bright,
	"filter-outline-hover": $filter-white,

	"shadow1": $blue-bright,
	"shadow2": $blue-dark,
	"shadow-opacity": .32,
	"shadow-hover-opacity": .40,
	"circle-shadow-opacity1": .32,
	"circle-shadow-opacity2": .32,
	"circle-shadow-opacity-hover1": .32,
	"circle-shadow-opacity-hover2": .32,

	// alt disabled positive
	"font-disabled": $white,
	"font-outline-disabled": #ADD1FC,
	"filter-disabled": $filter-white,
	"filter-outline-disabled": brightness(0) saturate(100%) invert(72%) sepia(49%) saturate(239%) hue-rotate(177deg) brightness(102%) contrast(98%),
	"bg-disabled": #ADD1FC,
	"bg-outline-disabled": $white,
	"border-outline-disabled": #ADD1FC,
	// alt disabled negative
	"font-disabled-inverse": #56656f,
	"font-outline-disabled-inverse": #56656f,
	"filter-disabled-inverse": brightness(0) saturate(100%) invert(35%) sepia(22%) saturate(313%) hue-rotate(161deg) brightness(101%) contrast(90%),
	"filter-outline-disabled-inverse": brightness(0) saturate(100%) invert(37%) sepia(20%) saturate(343%) hue-rotate(161deg) brightness(98%) contrast(89%),
	"bg-disabled-inverse": #05386c,
	"bg-outline-disabled-inverse": $blue-dark,
	"border-outline-disabled-inverse": #56656f
),

"gold": (
	"font": $blue-dark,
	"bg": $gold,
	"filter": $filter-blue-dark,

	"shadow1": #622E00,
	"shadow2": $blue-dark,
	"shadow-opacity": .20,
	"shadow-hover-opacity": .24,
	"circle-shadow-opacity1": .20,
	"circle-shadow-opacity2": .20,
	"circle-shadow-opacity-hover1": .24,
	"circle-shadow-opacity-hover2": .24,

	// alt disabled positive
	"font-disabled": #B0B7BB,
	"filter-disabled": brightness(0) saturate(100%) invert(65%) sepia(85%) saturate(21%) hue-rotate(158deg) brightness(97%) contrast(86%),
	"bg-disabled": linear-gradient(to bottom right, #FFEAAE 0%, #FFEAAE 50%, #FFD9AE 100%),
	// alt disabled negative
	"font-disabled-inverse": $blue-dark,
	"filter-disabled-inverse": $filter-blue-dark,
	"bg-disabled-inverse": linear-gradient(to bottom right, #56501d 0%, #56501d 50%, #563e1d 100%),
),

"dark": (
	"font-outline": $blue-dark,
	"font-outline-hover": $white,
	"bg-outline": $white,
	"bg-outline-hover": $blue-dark,
	"border-outline": $blue-dark,
	"filter-outline": $filter-blue-dark,
	"filter-outline-hover": $filter-white,

	"shadow1": $blue-dark,
	"shadow2": $blue-dark,
	"shadow-opacity": 0.24,
	"shadow-hover-opacity": .40,
	"circle-shadow-opacity1": .24,
	"circle-shadow-opacity2": .16,
	"circle-shadow-opacity-hover1": .24,
	"circle-shadow-opacity-hover2": .16,

	// alt disabled positive
	"font-outline-disabled": #B0B7BB,
	"filter-outline-disabled": brightness(0) saturate(100%) invert(65%) sepia(85%) saturate(21%) hue-rotate(158deg) brightness(97%) contrast(86%),
	"bg-outline-disabled": $white,
	"border-outline-disabled": #B0B7BB,
	// alt disabled negative
	"font-outline-disabled-inverse": #56656f,
	"filter-outline-disabled-inverse": brightness(0) saturate(100%) invert(37%) sepia(20%) saturate(343%) hue-rotate(161deg) brightness(98%) contrast(89%),
	"bg-outline-disabled-inverse": $blue-dark,
	"border-outline-disabled-inverse": #56656f,
)
);
